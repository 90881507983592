import request from "@/base/util/request.js";
// GET /sys/backstage-user/getBackstageUserPage
// 查询列表
export const getBackstageUserPage = (data) => {
    return request({
        url: '/sys/backstage-user/getBackstageUserPage',
        method: 'post',
        data
    })
}

// GET /sys/backstage-user/had-login/delete
//删除
export const DataDelete = (params) => {
    return request({
        url: '/sys/backstage-user/had-login/delete',
        method: 'get',
        params
    })
}

// GET /sys/backstage-user/had-login/resetPassword
// 重置密码

export const resetPassword = (params) => {
    return request({
        url: '/sys/backstage-user/had-login/resetPassword',
        method: 'get',
        params
    })
}

// POST /sys/sys-department/had-login/AddOrUpdate
// 新增修改
export const AddOrUpdate = (data) => {
        return request({
            url: '/sys/sys-department/had-login/AddOrUpdate',
            method: 'post',
            data
        })
    }
    // GET /sys/sys-department/getSysDepartmentList 部门列表查询
export const getSysDepartmentList = (params) => {
    return request({
        url: '/sys/sys-department/getSysDepartmentList',
        method: 'get',
        params
    })
}

// GET /sys/sys-department/had-login/delete 删除
export const deleteData = (params) => {
    return request({
        url: '/sys/sys-department/had-login/delete',
        method: 'get',
        params
    })
}


// GET /sys/backstage-user/getBackstageUserDetail
// 单个查询

export const getBackstageUserDetail = (params) => {
    return request({
        url: '/sys/backstage-user/getBackstageUserDetail',
        method: 'get',
        params
    })
}


// 角色管理
// POST /com/had-login/sys/sys-role/rolePage
// 角色的分页查询
export const rolePage = (data) => {
        return request({
            url: '/com/had-login/sys/sys-role/rolePage',
            method: 'post',
            data
        })
    }
    // GET /com/had-login/sys/sys-role/deleteRole
    // 删除，多个用，隔开
export const deleteRole = (params) => {
    return request({
        url: '/com/had-login/sys/sys-role/deleteRole',
        method: 'get',
        params
    })
}

// POST /com/had-login/sys/sys-role/addOrUpdateRole
// 新增或编辑角色类型的信息和权限
export const addOrUpdateRole = (data) => {
    return request({
        url: '/com/had-login/sys/sys-role/addOrUpdateRole',
        method: 'post',
        data
    })
}

// 根据类型获取用户自己的管理权限
export const getLoginUserMenuByType = (params) => {
    return request({
        url: '/com/had-login/sys/sys-menu/getLoginUserMenuByType',
        method: 'get',
        params
    })
}



// GET /com/had-login/sys/sys-menu/getAuthorityByType
// 新增编辑角色时回显的管理权限
export const getAuthorityByType = (params) => {
    return request({
        url: '/com/had-login/sys/sys-menu/getAuthorityByType',
        method: 'get',
        params
    })
}

// GET /com/had-login/sys/sys-menu/getServiceAuthority
// 新增编辑角色时回显的业务权限
export const getServiceAuthority = (params) => {
    return request({
        url: '/com/had-login/sys/sys-menu/getServiceAuthority',
        method: 'get',
        params
    })
}


// 
// 单个查询
export const getSysRoleDetail = (params) => {
    return request({
        url: '/com/had-login/sys/sys-role/getSysRoleDetail',
        method: 'get',
        params
    })
}





//POST /com/had-login/sys/sys-role/UpdateRole
// 编辑角色类型的信息和权限

export const UpdateRole = (data) => {
    return request({
        url: '/com/had-login/sys/sys-role/UpdateRole',
        method: 'post',
        data
    })
}



//====================================== 新增用户================================

// 新增用户POST /sys/backstage-user/had-login/AddOrUpdate
export const AddOrUpdateUser = (data) => {
    return request({
        url: '/sys/backstage-user/had-login/AddOrUpdate',
        method: 'post',
        data
    })
}

// GET /sys/sys-department/getLoginUserDepartmentParkAll
// 根据用户id查询全部部门信息
export const getLoginUserDepartmentParkAll = (params) => {
    return request({
        url: '/sys/sys-department/getLoginUserDepartmentParkAll',
        method: 'get',
        params
    })
}


// GET /com/had-login/sys/sys-menu/getAllMenuPermissionType
// 根据0业务权限或者1管理权限查询全部,层级结构
export const getAllMenuPermissionType = (params) => {
    return request({
        url: '/com/had-login/sys/sys-menu/getAllMenuPermissionType',
        method: 'get',
        params
    })
}